// Default Berry template config is merged with environment variables
const config = {
    ...{
        // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
        // like '/berry-material-react/react/default'
        basename: '',
        defaultPath: '/geolocation/map',
        fontFamily: `'Roboto', sans-serif`,
        borderRadius: 8
    },
    ...window.sigpEnv
};
delete window.sigpEnv;
export default config;
