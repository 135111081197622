import JsPDF from 'jspdf';

class PdfGenerator {
    constructor() {
        this.positionsHeaders = [
            {
                id: 'date',
                name: 'date',
                prompt: 'Date',
                width: 57,
                align: 'left',
                padding: 0
            },
            {
                id: 'latitude',
                name: 'latitude',
                prompt: 'Latitude',
                width: 57,
                align: 'left',
                padding: 0
            },
            {
                id: 'longitude',
                name: 'longitude',
                prompt: 'Longitude',
                width: 57,
                align: 'left',
                padding: 0
            },
            {
                id: 'altitude',
                name: 'altitude',
                prompt: 'Altitude',
                width: 57,
                align: 'left',
                padding: 0
            }
        ];
        this.positionsTablesConfig = {
            fontSize: 8,
            padding: 2,
            headerBackgroundColor: '#fff',
            margins: {
                top: 20,
                bottom: 20
            }
        };
    }

    // eslint-disable-next-line class-methods-use-this
    generateFromRoutes(routes, startDate, endDate, author) {
        const doc = new JsPDF();
        const toc = [];

        this.generateFrontPage(doc, startDate, endDate, author)
            .generatePositionsTablesFromRoutes(doc, routes, toc)
            .generateToc(doc, toc)
            .generatePagesNumbers(doc);

        return new Blob([doc.output('arraybuffer')], { type: 'application/octet-stream' });
    }

    // Below are "private methods"

    // eslint-disable-next-line class-methods-use-this
    generateFrontPage(doc, startDate, endDate, author) {
        const nowStr = new Date().toDateString();
        const startDateStr = startDate.toLocaleString('en-US', { hour12: false });
        const endDateStr = endDate.toLocaleString('en-US', { hour12: false });

        doc.addImage('/syntony-logo-large.png', 20, 20, 70, 51);
        doc.setFontSize(14);
        doc.setFont('Helvetica', 'normal', 'bold');
        doc.text(`Trackers positions from ${startDateStr} to ${endDateStr}`, doc.internal.pageSize.getWidth() / 2, 130, 'center');
        doc.line(65, 143, doc.internal.pageSize.getWidth() - 65, 143);

        doc.setFont('Helvetica', 'normal', 'normal');
        doc.setFontSize(12);
        doc.text(`Date: ${nowStr}`, doc.internal.pageSize.getWidth() / 2, 150, 'center');

        doc.setFontSize(8);
        doc.text(`Generated by ${author} using SYNTONY Softspot IOT Geolocation Platform`, 20, doc.internal.pageSize.getHeight() - 20);

        return this;
    }

    // eslint-disable-next-line class-methods-use-this
    generateToc(doc, toc) {
        doc.insertPage(2);
        doc.setFontSize(12);
        doc.setFont('Helvetica', 'normal', 'bold');
        doc.text('Contents', 20, 20);
        doc.setFont('Helvetica', 'normal', 'normal');
        doc.setFontSize(10);

        let height = 30;
        toc.forEach((content) => {
            doc.textWithLink(content.title, 20, height, { pageNumber: content.pageNumber + 1 });
            height += 10;
        });

        return this;
    }

    generatePositionsTablesFromRoutes(doc, routes, toc) {
        // Extract positions per tracker from routes
        const trackersPositions = routes.reduce((acc, cur) => {
            let curTrackerPositions = acc.filter((trackerPos) => trackerPos.tracker.uuid === cur.tracker.uuid)[0];
            if (curTrackerPositions === undefined) {
                curTrackerPositions = {
                    tracker: cur.tracker,
                    positions: []
                };
                acc.push(curTrackerPositions);
            }

            curTrackerPositions.positions = curTrackerPositions.positions.concat(
                cur.positions.map((pos) => ({
                    date: pos.date.toLocaleString('en-US', { hour12: false }),
                    latitude: pos.lat === null ? 'unknown' : pos.lat.toString(),
                    longitude: pos.lon === null ? 'unknown' : pos.lon.toString(),
                    altitude: pos.alt === null ? 'unknown' : pos.alt.toString()
                }))
            );

            return acc;
        }, []);

        // Sort per group and tracker name
        trackersPositions.sort((a, b) => {
            if (a.tracker.group_fqn === b.tracker.group_fqn) {
                return a.tracker.name > b.tracker.name ? 1 : -1;
            }
            return a.tracker.group_fqn > b.tracker.group_fqn ? 1 : -1;
        });

        // Generate Tables
        trackersPositions.forEach((trackerPos, i) => {
            const title = `${i + 1}   ${trackerPos.tracker.fqn(' > ')}`;
            doc.addPage();
            doc.setFontSize(12);
            doc.setFont('Helvetica', 'normal', 'bold');
            doc.text(title, 20, 20);
            toc.push({ title, pageNumber: doc.internal.getCurrentPageInfo().pageNumber });
            doc.table(20, 30, trackerPos.positions, this.positionsHeaders, this.positionsTablesConfig);
        });

        return this;
    }

    // eslint-disable-next-line class-methods-use-this
    generatePagesNumbers(doc) {
        for (let i = 2; i <= doc.internal.getNumberOfPages(); i += 1) {
            doc.setPage(i);
            doc.text((i - 1).toString(), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
        }

        return this;
    }
}

export default PdfGenerator;
