import geolocation from './geolocation';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [geolocation, admin]
};

export default menuItems;
