/**
 * Csv conversion service
 */
class CsvGenerator {
    constructor() {
        this.positionsHeaders = ['date', 'groups', 'tracker', 'tracker_uuid', 'latitude', 'longitude', 'altitude'];
    }

    generateFromRoutes(routes) {
        const csvArray = routes.reduce(
            (acc, cur) => {
                const positionsArray = cur.positions.map((pos) => [
                    pos.date.toISOString(),
                    cur.tracker.group.fqn(' > '),
                    cur.tracker.name,
                    cur.tracker.uuid,
                    pos.lat,
                    pos.lon,
                    pos.alt
                ]);
                return acc.concat(positionsArray);
            },
            [this.positionsHeaders]
        );

        // Convert to string
        const csv = csvArray
            .map((row) =>
                row
                    .map(String)
                    .map((v) => `"${v}"`)
                    .join(',')
            )
            .join('\r\n');
        return new Blob([csv], { type: 'text/csv;charset=utf-8' });
    }
}

export default CsvGenerator;
