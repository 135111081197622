import Tracker from './Tracker';
import AbstractGalaxieServiceRepository from './AbstractGalaxieServiceRepository';

/**
 * Trackers groups repository
 * This class wraps APIs calls to SITM service
 */
class TrackerRepository extends AbstractGalaxieServiceRepository {
    constructor(host, port, tls, tokenCallback) {
        super(host, port, tls, tokenCallback);
        this.find = this.find.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
    }

    /**
     * Find trackers
     * @param {Object=} params - Parameters
     * @param {Array.<Group>} params.groups - Filter trackers by groups
     * @returns {Promise<Array.<Tracker>>}
     */
    async find(params) {
        const reqParams = {};
        if (params.groups !== undefined) {
            reqParams.group_uuids = params.groups.map((group) => group.uuid).join();
        }

        const res = await this.axios.get('/trackers', { params: reqParams });
        if (res.data === null) {
            return [];
        }
        return res.data.map((obj) => Tracker.createFromSitm(obj)).sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    /**
     * Persist a tracker. If tracker's UUID is undefined, it is considered new. If the UUID is set it is considered as
     * exising and will be updated.
     * @param {Tracker} tracker
     * @returns {Promise.<Tracker>} - Refreshed tracker object after being persisted
     */
    async save(tracker) {
        let res;
        let uri;
        if (tracker.uuid == null) {
            res = await this.axios.post('/trackers', tracker.toSitm());
            uri = res.headers.location;
        } else {
            uri = `/trackers/${tracker.uuid}`;
            await this.axios.put(uri, tracker.toSitm());
        }
        res = await this.axios.get(uri);
        return Tracker.createFromSitm(res.data);
    }

    /**
     * Save many trackers
     * @param {Array.<Tracker>} trackers
     * @returns {Promise<void>}
     */
    async saveMany(trackers) {
        trackers.forEach((trk) => this.save(trk));
    }

    /**
     * Delete a tracker
     * @param {Tracker} tracker
     * @returns {Promise<void>}
     */
    async delete(tracker) {
        await this.axios.delete(`/trackers/${tracker.uuid}`);
    }

    /**
     * Delete many trackers
     * @param {Array.<Tracker>} trackers
     * @returns {Promise<void>}
     */
    async deleteMany(trackers) {
        trackers.forEach((trk) => this.delete(trk));
    }
}

export default TrackerRepository;
