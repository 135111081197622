/**
 * Trackers group entity
 */
import Tracker from './Tracker';

class Group {
    /** @type {String} */
    uuid;

    /** @type {String} */
    name;

    /** @type {String} */
    userGroup;

    /** @type {String} */
    userUuid;

    /** @type {String} */
    parentUuid;

    /** @type {Group} */
    parentGroup;

    /** @type {Array.<Tracker>} */
    trackers;

    /** @type {GroupsTree} */

    /** @type {Date} */
    createdAt;

    /** @type {Date} */
    updatedAt;

    /**
     * Instantiate a group object from a SITM group object (mainly used internally)
     * @param {Object} obj - Group object as received from SITM Rest server
     * @param {GroupsTree=} subGroupsTree - Tree of subgroups owned by this group
     * @returns {Group}
     */
    static createFromSitm(obj, subGroupsTree) {
        const group = new this();
        group.uuid = obj.uuid;
        group.name = obj.name;
        group.userGroup = obj.user_group;
        group.userUuid = obj.user_uuid;
        group.parentUuid = obj.parent_uuid;
        group.trackers =
            obj.trackers !== undefined
                ? obj.trackers.map((trk) => Tracker.createFromSitm(trk, group)).sort((a, b) => (a.name > b.name ? 1 : -1))
                : [];
        group.createdAt = new Date(obj.created_at);
        group.updatedAt = new Date(obj.updated_at);
        group.groups = subGroupsTree;
        return group;
    }

    /**
     * Get the fully qualified name (FQN) of this group.
     * FQN is the aggregation of the names of all the parents group as a path.
     * @param {String} separator - Chars sequence separating each group name
     * @param {boolean=} withUserGroup - Prepend user group to FQN, default to false
     * @returns {String} - FQN
     */
    fqn(separator, withUserGroup) {
        withUserGroup = withUserGroup === undefined ? false : withUserGroup;
        const fqn = (this.parentGroup ? this.parentGroup.fqn(separator) + separator : '') + this.name;
        return withUserGroup ? this.userGroup + separator + fqn : fqn;
    }

    /**
     * Convert this group to SITM API object (mainly used internally)
     * @returns {Object} - Object to be used with SITM Rest server requests
     */
    toSitm() {
        const obj = {
            name: this.name,
            parent_uuid: this.parentUuid,
            user_group: this.userGroup
        };
        if (this.uuid) {
            obj.uuid = this.uuid;
        }
        return obj;
    }
}

export default Group;
