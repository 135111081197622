import Group from './Group';
import GroupsTree from './GroupsTree';
import AbstractGalaxieServiceRepository from './AbstractGalaxieServiceRepository';

/**
 * Trackers groups repository
 * This class wraps APIs calls to SITM service
 */
class GroupRepository extends AbstractGalaxieServiceRepository {
    constructor(host, port, tls, tokenCallback) {
        super(host, port, tls, tokenCallback);
        this.findAll = this.findAll.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
    }

    /**
     * Find groups.
     * @param {Object=} params - Parameters
     * @param {boolean} [params.withTrackers = false] - Fetch groups trackers as well and store them in group objects.
     * @returns Promise<GroupsTree>
     */
    async findAll(params) {
        const defaultParams = {
            withTrackers: false
        };
        params = params !== undefined ? { ...defaultParams, ...params } : defaultParams;

        const res = await this.axios.get('/groups', {
            params: {
                withTrackers: params.withTrackers.toString()
            }
        });
        if (res.data === null) {
            return null;
        }
        return GroupsTree.createFromSitm(res.data);
    }

    /**
     * Persist a group. If group's UUID is undefined, it is considered new. If the UUID is set it is considered as
     * exising and will be updated.
     * @param {Group} group
     * @returns {Promise.<Group>} - Refreshed group object after being persisted
     */
    async save(group) {
        let res;
        let uri;
        if (group.uuid == null) {
            res = await this.axios.post('/groups', group.toSitm());
            uri = res.headers.location;
        } else {
            uri = `/groups/${group.uuid}`;
            await this.axios.put(uri, group.toSitm());
        }
        res = await this.axios.get(uri);
        return Group.createFromSitm(res.data);
    }

    /**
     * Delete a group
     * @param {Group} group
     * @returns {Promise<void>}
     */
    async delete(group) {
        await this.axios.delete(`/groups/${group.uuid}`);
    }
}

export default GroupRepository;
