/**
 * Enum of distinct tracking types
 */
export const TrackingType = Object.freeze({
    Regular: 'regular',
    Accelerometer: 'accelerometer'
});

/**
 * Tracker entity
 */
class Tracker {
    /** @type {String} */
    uuid;

    /** @type {String} */
    name;

    /** @type {String} */
    trackingType;

    /** @type {number} */
    trackingFrequencyVal;

    /** @type {String} */
    telecomLink;

    /** @type {boolean} */
    gatewayEnabled;

    /** @type {Object} */
    coarsePosition;

    /** @type {String} */
    loraTtnId;

    /** @type {String} */
    subwaveSiteUuid;

    /** @type {String} */
    accessPointSsid;

    /** @type {String} */
    accessPointPassword;

    /** @type {number} */
    ShortCyclePeriod;

    /** @type {number} */
    ShortCycleWindow;

    /** @type {boolean} */
    UpToDate;

    /** @type {number} */
    Debug1;

    /** @type {number} */
    Battery;

    /** @type {String} */
    groupUuid;

    /** @type {Group} */
    group;

    /** @type {String} */
    userGroup;

    /** @type {String} */
    userUuid;

    /** @type {Date} */
    createdAt;

    /** @type {Date} */
    updatedAt;

    /**
     * Instantiate a tracker object from a SITM group object (mainly used internally)
     * @param {Object} obj - Tracker object as received from SITM Rest server
     * @param {Group=} group - Parent group
     * @returns {Tracker}
     */
    static createFromSitm(obj, group) {
        const trk = new this();
        trk.uuid = obj.uuid;
        trk.name = obj.name;
        trk.trackingType = obj.tracking_type;
        trk.trackingFrequency = obj.tracking_frequency;
        trk.telecomLink = obj.telecom_link;
        trk.gatewayEnabled = obj.gateway_enabled;
        trk.coarsePosition = obj.coarse_position;
        trk.loraTtnId = obj.lora_ttn_id;
        trk.subwaveSiteUuid = obj.subwave_site_uuid;
        trk.accessPointSsid = obj.access_point_ssid;
        trk.accessPointPassword = obj.access_point_password;
        trk.shortCyclePeriod = obj.short_cycle_period;
        trk.shortCycleWindow = obj.short_cycle_window;
        trk.upToDate = obj.up_to_date;
        trk.debug1 = obj.debug1;
        trk.battery = obj.battery;
        trk.groupUuid = obj.group_uuid;
        trk.group = group;
        trk.userUuid = obj.user_uuid;
        trk.userGroup = obj.user_group;
        trk.createdAt = new Date(obj.created_at);
        trk.updatedAt = new Date(obj.updated_at);
        return trk;
    }

    get trackingFrequency() {
        return this.trackingFrequencyVal;
    }

    set trackingFrequency(value) {
        this.trackingFrequencyVal = typeof value === 'string' ? parseInt(value, 10) : value;
    }

    /**
     * Get the fully qualified name (FQN) of this tracker.
     * FQN is the aggregation of the names of all the parents group with the tracker name (leaf) as a path.
     * @param {String} separator - Chars sequence separating each group name
     * @returns {String} - FQN
     */
    fqn(separator) {
        return (this.group ? this.group.fqn(separator) + separator : '') + this.name;
    }

    /**
     * Convert this tracker to SITM API object (mainly used internally)
     * @returns {Object} - Object to be used with SITM Rest server requests
     */
    toSitm() {
        return {
            uuid: this.uuid,
            name: this.name,
            tracking_type: this.trackingType,
            tracking_frequency: this.trackingFrequency,
            telecom_link: this.telecomLink,
            group_uuid: this.groupUuid,
            gateway_enabled: this.gatewayEnabled,
            lora_ttn_id: this.loraTtnId,
            subwave_site_uuid: this.subwaveSiteUuid,
            coarse_position: this.coarsePosition,
            access_point_ssid: this.accessPointSsid,
            access_point_password: this.accessPointPassword,
            short_cycle_period: this.shortCyclePeriod,
            short_cycle_window: this.shortCycleWindow,
            up_to_date: this.upToDate,
            debug1: this.debug1,
            battery: this.battery
        };
    }
}

export default Tracker;
