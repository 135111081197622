import Group from './Group';

/**
 * This class is a tree oriented group store which offers methods to ease tree manipulation.
 * Internally this class stores references to every group with the same parent Uuid.
 * It stores as well references to every group and subgroup of the tree as a flat object indexed by UUID
 * to speed up finding by UUID.
 */
class GroupsTree {
    groups = [];

    flatUuidIndex = {};

    /**
     * Instantiate a new GroupsTree from a list of SITM group objects
     * @param {Array.<Object>} objs - Group objects as received from SITM Rest server
     * @returns {GroupsTree}
     */
    static createFromSitm(objs) {
        return this.buildTreeFromSitm(objs, '');
    }

    /**
     * Helper method for createFromSitm
     * Instantiate recursively the tree and group objects.
     * @params {Array.<Object>} objs - SITM objects
     * @params {String|null} parentUuid - The parent group UUID which will be the root level of the GroupsTree
     * @returns {GroupsTree}
     */
    static buildTreeFromSitm(objs, parentUuid) {
        // Separate objects having the given parentUuid
        // to others (remove found objects from the object list
        // to speedup recursion scanning).
        const foundObjs = [];
        objs = objs.reduce((prev, cur) => {
            if (cur.parent_uuid === (parentUuid == null ? '' : parentUuid)) {
                foundObjs.push(cur);
                return prev;
            }
            prev.push(cur);
            return prev;
        }, []);

        const groups = [];
        let flatUuidIndex = {};
        foundObjs.forEach((obj) => {
            const subGroupsTree = this.buildTreeFromSitm(objs, obj.uuid);
            const group = Group.createFromSitm(obj, subGroupsTree);
            subGroupsTree.setParentGroup(group);
            groups.push(group);
            flatUuidIndex = { ...flatUuidIndex, ...subGroupsTree.flatUuidIndex };
            flatUuidIndex[group.uuid] = group;
        });

        groups.sort((a, b) => (a.name > b.name ? 1 : -1));
        const tree = new this();
        tree.groups = groups;
        tree.flatUuidIndex = flatUuidIndex;
        return tree;
    }

    /**
     * Return the number of top level (root) groups in the tree
     * @returns {number}
     */
    get rootGroupsLength() {
        return this.groups.length;
    }

    /**
     * Return every group of the tree as a flat array
     * @returns <Array.<Group>>
     */
    get all() {
        return Object.values(this.flatUuidIndex).sort((a, b) => (a.fqn(',') > b.fqn(',') ? 1 : -1));
    }

    /**
     * Set parent group reference for top level groups of the tree
     * @param {Group} parentGroup
     */
    setParentGroup(parentGroup) {
        this.groups.forEach((group) => {
            group.parentGroup = parentGroup;
        });
        this.parentGroup = parentGroup;
    }

    /**
     * Search the Group with the given UUID
     * @param {String} uuid
     * @returns {Group|null}
     */
    findByUuid(uuid) {
        return uuid in this.flatUuidIndex ? this.flatUuidIndex[uuid] : null;
    }

    /**
     * Split this GroupsTree into many, one per user group.
     * @returns {Array.<GroupsTree>}
     */
    groupByUserGroups() {
        const userGroups = this.groups.reduce((userGroups, group) => {
            if (userGroups[group.userGroup] === undefined) {
                userGroups[group.userGroup] = {
                    name: group.userGroup,
                    groupsTree: new GroupsTree()
                };
            }
            userGroups[group.userGroup].groupsTree.groups.push(group);
            return userGroups;
        }, {});
        return Object.values(userGroups).sort((a, b) => (a.name > b.name ? 1 : -1));
    }
}

export default GroupsTree;
