import md5 from 'crypto-js/md5';

/**
 * Route position entity
 */
class Position {
    /** @type {number} */
    lat;

    /** @type {number} */
    lon;

    /** @type {number} */
    alt;

    /** @type {Date} */
    date;

    /**
     * Instantiate a position object from a SIPC position object (mainly used internally)
     * @param {Object} obj - Position object as received from SIPC Rest server
     * @param {Route} route - Route associated to this position
     * @returns {Position}
     */
    static createFromSipc(obj, route) {
        const pos = new this();
        Object.assign(pos, obj);
        pos.route = route;
        pos.date = new Date(obj.date);
        return pos;
    }

    /**
     * Get uniq MD5 hash for this object based on its values
     * @returns {String}
     */
    get hash() {
        return md5(this.route.hash + this.date + this.lat + this.lon);
    }

    /**
     * Tell whether the position has geo coords values fulfilled.
     * (Some positions computed by SIPC may be invalid).
     * @returns {boolean}
     */
    isValid() {
        return !!(this.lat && this.lon);
    }
}

export default Position;
