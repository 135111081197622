import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { Button, CssBaseline, StyledEngineProvider } from '@mui/material';

import { useKeycloak } from '@react-keycloak/web';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { ServicesProvider } from 'contexts/ServicesContext';

import { Route } from 'react-router-dom';
import { Navigate, Routes } from 'react-router';
import Loadable from './ui-component/Loadable';
import { createRef, lazy } from 'react';
import MainLayout from './layout/MainLayout';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

const Login = Loadable(lazy(() => import('views/login')));
const GeoMap = Loadable(lazy(() => import('views/geolocation/map')));
const AdminTrackers = Loadable(lazy(() => import('views/admin/trackers')));

// ==============================|| APP ||============================== //

const Logged = () => {
    const { keycloak } = useKeycloak();

    return (
        <>
            <p>Welcome!</p>
            <button type="button" onClick={() => keycloak.logout()}>
                Logout..
            </button>
        </>
    );
};

const SecuredRoutes = ({ children }) => {
    const { keycloak, initialized } = useKeycloak();

    if (!initialized) {
        return <p>Initializing...</p>;
    }

    if (!keycloak.authenticated) {
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Login />} />
            </Routes>
        );
    }

    return (
        <ServicesProvider>
            <Routes>
                <Route path="/login" element={<Navigate to="/" replace />} />
                {children}
            </Routes>
        </ServicesProvider>
    );
};

SecuredRoutes.propTypes = {
    children: PropTypes.element
};

const App = () => {
    const customization = useSelector((state) => state.customization);
    const { keycloak, initialized } = useKeycloak();
    const notistackRef = createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <SnackbarProvider
                        maxSnack={3}
                        ref={notistackRef}
                        action={(key) => <Button onClick={onClickDismiss(key)}>DISMISS</Button>}
                    >
                        <SecuredRoutes>
                            <Route path="/" element={<MainLayout />}>
                                <Route path="/geolocation/map" element={<GeoMap />} />
                                {initialized && keycloak.authenticated && keycloak.tokenParsed.realm_access.roles.includes('IOT_admin') ? (
                                    <>
                                        <Route path="/admin/trackers" element={<AdminTrackers />} />
                                    </>
                                ) : null}
                                <Route path="/logged" element={<Logged />} />
                                <Route path="/" element={<Navigate to="/geolocation/map" replace />} />
                                <Route path="*" element={<Navigate to="/geolocation/map" replace />} />
                            </Route>
                        </SecuredRoutes>
                    </SnackbarProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
