// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useKeycloak } from '@react-keycloak/web';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { keycloak } = useKeycloak();

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                if (
                    item.requiredRole !== undefined &&
                    (!keycloak.authenticated || !keycloak.tokenParsed.realm_access.roles.includes(item.requiredRole))
                ) {
                    return null;
                }
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
