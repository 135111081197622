// assets
import { IconMap } from '@tabler/icons';

// constant
const icons = {
    IconMap
};

const geolocation = {
    id: 'geolocation',
    title: 'Geolocation',
    type: 'group',
    children: [
        {
            id: 'map',
            title: 'Map',
            type: 'item',
            url: '/geolocation/map',
            icon: icons.IconMap,
            breadcrumbs: false
        }
    ]
};

export default geolocation;
