/**
 * React Context provider holding services components
 *
 * Services are non-react objects holding business logic.  As react only
 * focuses on UI components, business logic has to go into separate
 * classes/objects to make a clean separation of concerns.
 *
 * This context acts like a simple DI/registry container which makes the glue
 * between services classes and react components.
 *
 * It seems the recommended way of doing this in react, see:
 * <https://blog.testdouble.com/posts/2021-03-19-react-context-for-dependency-injection-not-state/>
 * <https://marmelab.com/blog/2019/03/13/react-dependency-injection.html>
 * <https://stackoverflow.com/questions/35855781/having-services-in-react-application>
 */
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import config from 'config';
import keycloak from '../services/Keycloak';
import GroupRepository from 'domain/GroupRepository';
import RouteRepository from '../domain/RouteRepository';
import DirectionsCalculator from '../domain/DirectionsCalculator';
import CsvGenerator from '../services/CsvGenerator';
import PdfGenerator from '../services/PdfGenerator';
import TrackerRepository from '../domain/TrackerRepository';
import UserGroupRepository from '../domain/UserGroupRepository';

const services = {
    userGroupRepo: new UserGroupRepository(config.sitmHost, config.sitmPort, config.sitmTls === 'true', () => keycloak.token),
    groupRepo: new GroupRepository(config.sitmHost, config.sitmPort, config.sitmTls === 'true', () => keycloak.token),
    trackerRepo: new TrackerRepository(config.sitmHost, config.sitmPort, config.sitmTls === 'true', () => keycloak.token),
    routeRepo: new RouteRepository(config.sipcHost, config.sipcPort, config.sipcTls === 'true', () => keycloak.token),
    directionsCalculator: new DirectionsCalculator(config.mapboxAccessToken),
    csvGenerator: new CsvGenerator(),
    pdfGenerator: new PdfGenerator()
};

const ServicesContext = createContext({});

export function useServices() {
    return useContext(ServicesContext);
}

export function ServicesProvider({ children }) {
    return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>;
}

ServicesProvider.propTypes = {
    children: PropTypes.any
};
