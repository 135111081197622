import axios from 'axios';

/**
 * Returns a valid access token.
 * The token has to be refreshed if required.
 * @callback refreshToken
 * @returns {String} - A valid access token
 */

/**
 * Abstract base class for repositories which interface with a Syntony Galaxie Rest API microservice
 */
class AbstractGalaxieServiceRepository {
    /**
     * @param {String} host - Server hostname
     * @param {String} port - Server port
     * @param {boolean} tls - Make use of TLS or not (http/https)
     * @param {refreshToken} refreshToken - Callback that is called to refresh the access token
     */
    constructor(host, port, tls, refreshToken) {
        this.refreshToken = refreshToken;
        this.axiosInstance = axios.create({
            baseURL: `http${tls ? 's' : ''}://${host}:${port}`,
            headers: {
                Accept: '*/*'
            }
        });
    }

    /**
     * Return the current axios instance with up-to-date token
     * @returns {Object} - Axios instance
     */
    get axios() {
        this.axiosInstance.defaults.headers.Authorization = `Bearer ${this.refreshToken()}`;
        return this.axiosInstance;
    }
}

export default AbstractGalaxieServiceRepository;
