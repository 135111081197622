// assets
import { IconFolders, IconRouter } from '@tabler/icons';

// constant
const icons = {
    IconFolders,
    IconRouter
};

const admin = {
    id: 'admin',
    title: 'Administration',
    type: 'group',
    requiredRole: 'IOT_admin',
    children: [
        {
            id: 'trackers',
            title: 'Trackers',
            type: 'item',
            url: '/admin/trackers',
            icon: icons.IconRouter,
            breadcrumbs: false
        }
    ]
};

export default admin;
