/**
 * Trackers routes repository
 * This class wraps APIs calls to SIPC service
 */
import Route from './Route';
import AbstractGalaxieServiceRepository from './AbstractGalaxieServiceRepository';

class RouteRepository extends AbstractGalaxieServiceRepository {
    constructor(host, port, tls, tokenCallback) {
        super(host, port, tls, tokenCallback);
        this.find = this.find.bind(this);
    }

    /**
     * Find routes for the given trackers between at the given interval of time
     * @param {Date} dateMin - Lower bound of the time interval
     * @param {Date} dateMax - Upper bound of the time interval
     * @param trackers {Array.<Tracker>}
     * @returns {Promise<Array.<Route>>}
     */
    async find(dateMin, dateMax, trackers) {
        const uuids = trackers.map((tracker) => tracker.uuid).join();
        const res = await this.axios.get('/routes', {
            params: {
                date_min: dateMin.toISOString(),
                date_max: dateMax.toISOString(),
                tracker_uuids: uuids
            }
        });
        if (res.data === null) {
            return [];
        }

        return res.data.map((obj) => Route.createFromSipc(obj, trackers.filter((tracker) => tracker.uuid === obj.tracker_uuid)[0]));
    }
}

export default RouteRepository;
