import AbstractGalaxieServiceRepository from './AbstractGalaxieServiceRepository';
import jsYaml from 'js-yaml';

/**
 * Users groups repository
 * This class wraps APIs calls to SITM service
 */
class UserGroupRepository extends AbstractGalaxieServiceRepository {
    constructor(host, port, tls, tokenCallback) {
        super(host, port, tls, tokenCallback);
        this.getYmlConfig = this.getYmlConfig.bind(this);
        this.saveYmlConfig = this.saveYmlConfig.bind(this);
    }

    /**
     * Get user group configuration in Yaml format
     * @param {string} userGroup - Name of the user group
     * @returns {Promise<String>}
     */
    async getYmlConfig(userGroup) {
        const res = await this.axios.get(`/userGroups/${userGroup}`, { headers: { Accept: 'application/x-yaml' } });
        return res.data;
    }

    /**
     * Save user group configuration in Yaml format
     * @param {string} yamlData - Yaml configuration data
     * @returns {object} parsed Yaml object
     */
    async saveYmlConfig(yamlData) {
        const userGroupConfig = jsYaml.load(yamlData);
        if (userGroupConfig.user_group === undefined || typeof userGroupConfig.user_group !== 'string') {
            throw new Error("Can't find user_group key in Yaml");
        }

        await this.axios.put(`/userGroups/${userGroupConfig.user_group}`, yamlData, {
            headers: { Accept: 'application/x-yaml', 'Content-Type': 'application/x-yaml' }
        });
        return userGroupConfig;
    }
}

export default UserGroupRepository;
