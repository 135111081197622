import axios from 'axios';

/**
 * GeoJSON lineString type object
 * @typedef {Object} Direction
 */

/**
 * Directions computation service
 *
 * This service makes use of the Mapbox Directions API
 * @See https://docs.mapbox.com/api/navigation/directions/
 */
class DirectionsCalculator {
    /**
     * @param {String} mapboxAccessToken
     */
    constructor(mapboxAccessToken) {
        this.mapboxAccessToken = mapboxAccessToken;
        this.axiosInstance = axios.create({
            baseURL: 'https://api.mapbox.com/directions/v5/mapbox/driving'
        });
        this.computeDirection = this.computeDirection.bind(this);
    }

    /**
     * Compute a direction for the given positions
     * @param {Array.<Position>} positions
     * @returns {Promise<Direction>}
     */
    async computeDirection(positions) {
        if (positions < 2) {
            throw new Error('At least two positions are required to compute a route');
        }

        // Mapbox Directions API is limited up to 25 coord per request
        // So positions array is split in chunks of 25 and a request is
        // made for each. To make a non-interrupted route, next chunk start
        // with the position of the previous one, hence the 24 steps
        // for-loop statement.
        let promises = [];
        for (let i = 0; i < positions.length; i += 24) {
            const chunk = positions.slice(i, i + 25);
            const url = chunk.reduce((acc, cur) => acc.concat(acc !== '/' ? '%3B' : '', `${cur.lon}%2C${cur.lat}`), '/');
            promises = promises.concat(
                this.axiosInstance.get(url, {
                    params: {
                        alternatives: false,
                        geometries: 'geojson',
                        overview: 'full',
                        steps: false,
                        access_token: this.mapboxAccessToken
                    }
                })
            );
        }

        // Merge routes coordinates into single GeoJSON LineString object
        const results = await Promise.all(promises);
        return {
            type: 'LineString',
            coordinates: results.reduce((acc, cur) => acc.concat(cur.data.routes[0].geometry.coordinates), [])
        };
    }
}

export default DirectionsCalculator;
