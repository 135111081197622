/**
 * Route entity
 */
import md5 from 'crypto-js/md5';
import Position from './Position';

class Route {
    /** @type {Date} */
    startDate;

    /** @type {Date} */
    endDate;

    /**
     * @type {boolean} - Whether this route is complete (i.e. contains every position) on the lower bound
     */
    startPartial;

    /**
     * @type {boolean} - Whether this route is complete (i.e. contains every position) on the upper bound
     */
    endPartial;

    /** @type {Tracker} */
    tracker;

    /** @type {Array.<Position>} */
    positions;

    /**
     * Instantiate a group object from a SIPC group object (mainly used internally)
     * @param {Object} obj - Route object as received from SIPC Rest server
     * @param {Tracker=} tracker - Tracker associated to this route
     * @returns {Group}
     */
    static createFromSipc(obj, tracker) {
        const route = new this();
        route.startDate = new Date(obj.start_date);
        route.endDate = new Date(obj.end_date);
        route.startPartial = obj.start_partial;
        route.endPartial = obj.end_partial;
        route.tracker = tracker;
        route.positions = obj.positions.map((pos) => Position.createFromSipc(pos, route));
        return route;
    }

    /**
     * Return only positions with valid coordinates
     * @returns {Array.<Position>}
     */
    get validPositions() {
        return this.positions.filter((pos) => pos.lat && pos.lon);
    }

    /**
     * Tell whether this route contains at least one valid position
     * @returns {boolean}
     */
    get containsValidPositions() {
        return this.validPositions.length > 0;
    }

    /**
     * Get uniq MD5 hash for this object based on its values
     * @returns {String}
     */
    get hash() {
        return md5(this.tracker.uuid + this.positions[0].date + this.positions[this.positions.length - 1].date);
    }

    /**
     * Tell whether this route contains every position or not
     * @returns {boolean}
     */
    isPartial() {
        return this.startPartial || this.endPartial;
    }
}

export default Route;
